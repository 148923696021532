<template>
  <div>
    <div v-if="!user" >
      <a v-if="links.widgetsignin" v-on:click="openLoginWindow" class="t3d-button" v-bind:class="{ error: sessionError }">Sign In</a>
      <a v-else :href="links.signin" class="t3d-button" v-bind:class="{ error: sessionError }">Sign In</a>
    </div>
    <div v-if="user">
      <div class="t3d-profile" :class="theme">
        <div class="t3d-profile__account" v-on:click="profileToggle = !profileToggle">
          <img class="t3d-profile__avatar" src="../assets/avatar.svg"/>
          <div class="t3d-profile__details">
            <div class="t3d-profile__name">{{ user.given_name }} {{ user.family_name }}</div>
            <div class="t3d-profile__company">{{ user.company }}</div>
          </div>
          <img class="t3d-profile__triangle" src="../assets/triangle.svg" v-bind:class="{ active: profileToggle }"/>
        </div>
        <div class="t3d-profile__actions" v-bind:class="{ active: profileToggle }">
          <div class="t3d-profile__action">
            <a class="t3d-profile__actionlink" :href="links.dashboard">My Account</a>
            <a class="t3d-profile__actionlink" v-on:click="logUserOut()">Logout</a>
          </div>
          <!-- <div class="t3d-profile__action">
            <a class="t3d-profile__actionlink">Logout</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/* eslint-disable */
import { PortalSessionManager } from 'portal-session-manager/dist/PortalSessionManager'
//const baseUrl = 'https://manage.techsoft3d.com/'
// const baseUrl = 'https://staging.manage.techsoft3d.com/'
const baseUrl = 'http://localhost:3001'
const client = new PortalSessionManager(baseUrl)
const popupWidth=500;
const popupHeight=700;

export default {
  name: 'SessionStatus',
  props: ['theme'],
  data() {
    return {
      user: false,
      links: {},
      tokens: {},
      profileToggle: false,
      sessionError: false,
      portalLoginWindow: null,
      loginWindowTimer: null
    }
  },
  methods: {
    logUserOut () {
      client.logout()
        .then(() =>
          client.getSessionStatus(window.location.href)
            .then(({ links }) => {
              this.links = links
              this.user = undefined
              this.tokens = undefined
            })
            .catch(err => {
              if (err) {
                this.sessionError = true
              }
            })
        )
        .catch(err => console.error('FAIL', err))
    },
    getSession() {
      client.getSessionStatus(window.location.href)
      .then(({ user, links, tokens }) => {
        this.user = user
        this.links = links
        this.tokens = tokens
      })
      .catch(err => {
        if (err) {
          this.sessionError = true
        }
      });
    },
    onTimerCallbackToCheckLoginWindowClosure() {
      // If the window is closed, then reinit Facebook
      if (this.portalLoginWindow.closed)
      {
        clearInterval(this.loginWindowTimer);
        this.getSession();
      }
    },
    openLoginWindow() {
      var xPosition=(window.screen.width-popupWidth)/2;
      var yPosition=(window.screen.height-popupHeight)/2;

      this.portalLoginWindow = window.open(this.links['widgetsignin'], "LoginWindow",
        "location=1,scrollbars=1,"+
        "width="+popupWidth+",height="+popupHeight+","+
        "left="+xPosition+",top="+yPosition);

      this.loginWindowTimer=setInterval(this.onTimerCallbackToCheckLoginWindowClosure, 1000);
    }
  },
  watch: {
    // when responseCode changes we fire an event with the necessary data
    user: function () {
      var account = {}
      account['tokens'] = this.tokens
      account['user'] = this.user
      account['links'] = this.links
      var event = new CustomEvent('session-status', { detail: { account }, bubbles: true, composed: true } )
      // document.addEventListener('session-status', eventHandler)
      // function eventHandler(e) {
      //   console.log(e)
      // }
      document.dispatchEvent(event)

      //client uses this

    }
  },
  mounted() {
    this.getSession();
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

* {
  font-family: 'Open Sans', 'Lato', Arial, Helvetica, sans-serif;
}

.t3d-button {
  border: 1px solid #ccc;
  padding: 8px 10px;
  letter-spacing: 2px;
  position: relative;
  text-transform: uppercase;
  text-align: left;
  font-family: 'Open Sans', 'Lato', Arial, Helvetica, sans-serif;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
}
.t3d-button.error {
  opacity: 0.4;
  cursor: default;
}
.t3d-profile {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  letter-spacing: 2px;
  position: relative;
  text-transform: uppercase;
  text-align: left;
  font-family: 'Open Sans', 'Lato', Arial, Helvetica, sans-serif;
  font-size: 14px;
  display: inline-block;
}
.t3d-profile.demo {
  border: none;
}
.t3d-profile__account {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.t3d-profile__details {
  margin:0 14px;
}
.t3d-profile__name {
  color: #0090d0;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
}
.t3d-profile__company {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
}
.t3d-profile__triangle.active {
  transform: rotate(180deg)
}
.t3d-profile__avatar {
  max-width:40px;
  width: 100%;
}
.t3d-profile__actions {
  display: none;
  padding-left: 62px;
  padding-bottom: 14px;
  padding-top: 14px;
}
.t3d-profile__actions.active {
  display:block;
  position:absolute;
  top:50px;
  left:-1px;
  right:-1px;
  background: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
}
.t3d-profile.demo .t3d-profile__actions.active {
  border: 1px solid #ccc;
  border-radius: 8px;
  top: 60px;
  background: #f4f4f4;
  padding-bottom: 20px;
}
.t3d-profile__actionlink {
  border-bottom: 1px solid #ccc;
  padding: 2px 0;
  margin: 4px 0;
  display: inline-block;
  cursor: pointer;
}
.t3d-profile__actionlink:hover {
  background: transparent;
  color: #0090d0;
  border-color: #0090d0;
}
a {
  text-decoration: none;
  color: #666;
}
</style>
